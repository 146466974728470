
import * as React from 'react';
import useAuth from '@hooks/useAuth';

const LazyProviderHomeScreen = React.lazy(()=> import ('./ProviderHomeV2'));
const LazyCareNavScreen = React.lazy(()=> import ('../../CareNav'));


export default function getDynamicHomeScreens() {
  const { user } = useAuth();
  if (user.isEmployee) {
    return <LazyProviderHomeScreen />;
  }
  return <LazyCareNavScreen />;
}

